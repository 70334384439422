import { get } from "lodash";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

const Patients = dynamic(() => import("../../components/Patients"), {
  ssr: false,
  loading: () => (
    <div className="flex items-center justify-center bold text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-900 w-full h-full">
      Loading...
    </div>
  ),
});

const PatientsPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const patient_uid = Number(get(router, ["query", "params", "0"], 0));

  return (
    <Suspense fallback={`Loading...`}>
      <Patients patient_uid={patient_uid} />
    </Suspense>
  );
};

export async function getStaticProps(context) {
  return {
    // Passed to the page component as props
    props: {
      hasShare: true,
    },
  };
}

export const getStaticPaths = () => {
  return {
    paths: [], //indicates that no page needs be created at build time
    fallback: "blocking", //indicates the type of fallback
  };
};

export default PatientsPage;
